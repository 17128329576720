import React from 'react';

import ContentSection from '../components/content-section';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';

const ImpressumPage = (props) => {
  const title = 'Impressum';

  return (
    <Layout path={props.location.pathname}>
      <MetaData
        title={title}
        description="Das Impressum der Webseite von Coiffeur Cocoon."
      />

      <ContentSection>
        <h1>{title}</h1>

        <p className="mt-30">
          Coiffeur Cocoon
          <br />
          Jasmin Sheila Brechbühl
          <br />
          Bernstrasse 15
          <br />
          3600 Thun
        </p>

        <p className="mt-30">
          Telefon: +41 (0)33 221 17 78
          <br />
          E-Mail: info@coiffeur-cocoon.ch
          <br />
          Internet: www.coiffeur-cocoon.ch
        </p>

        <p className="mt-30">
          Firmenidentifikationsnummer (UID):
          <br />
          CHE-228.198.648
        </p>
      </ContentSection>
    </Layout>
  );
};

export default ImpressumPage;
